import React, {useEffect, useState} from 'react';

const GitHubRepository = (props) => {
    const [repos, setRepos] = useState([]);

    useEffect(() => {
        const fetchRepos = async () => {
            try {
                // Substitua 'seu_nome_de_usuário' pelo seu nome de usuário do GitHub
                const response = await fetch('https://api.github.com/users/VivaldiCode/repos');

                if (!response.ok) {
                    throw new Error('Erro ao buscar repositórios do GitHub');
                }

                // Converte a resposta para JSON
                const data = await response.json();

                // Atualiza o estado com os repositórios
                setRepos(data);
            } catch (error) {
                console.error('Erro ao buscar repositórios do GitHub:', error.message);
            }
        };

        // Chama a função de busca de repositórios ao montar o componente
        fetchRepos();
    }, []);  // O array vazio [] como segundo argumento garante que a requisição seja feita apenas uma vez ao montar o componente

    return (
        <div className="section">
            <div className="container cc-github">
                <div className="h4 text-center mb-4 title">GitHub</div>
                <div className="row">

                    {repos.map((repo) => (

                        <div className={'col col-4'} key={repo.id}>
                            <div className="card">
                                <div className="row">
                                    <div className="col-md-12 bg-primary" data-aos="fade-right" data-aos-offset="50"
                                         data-aos-duration="500">
                                        <div className="p-4 cc-education-header">
                                            <div className="h5 m-0">{repo.name}</div>
                                        </div>
                                    </div>
                                    <div className="col-md-12" data-aos="fade-left" data-aos-offset="50" data-aos-duration="500">
                                        <div className="card-body">
                                            <div className="h5 m-0 text-black">{repo.description}</div>
                                            <p className="category">{repo.language}</p>
                                            <p className="category"><a target="_blank" href={repo.html_url}>Link</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}

                </div>
            </div>
        </div>
    )
};

export default GitHubRepository;
