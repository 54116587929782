import React, {Component} from 'react';

import './precompiled/bootstrap.min.css';
//import './precompiled/aos.css';
import './App.css';

import Header from './sectors/Header';
import Profile from './sectors/Profile';
import About from './sectors/About';
import Skill from './sectors/Skill';
// import Portfolio from './sectors/Portfolio';
import Experience from './sectors/Experience';
import EducationSchools from './sectors/EducationSchools';
import PersonalProjects from './sectors/PersonalProjects';
import Contact from './sectors/Contact';
import pt from "./texts/pt.json"
import en from "./texts/en.json"

import GitHubRepository from "./sectors/GitHubRepository";

class App extends Component {
    constructor(props) {
        super(props);
        const userLanguage = navigator.language || navigator.userLanguage;
        let params = new URLSearchParams(window.location.search.replace('?', ""))

        let cvMode = params.get('cvMode') === 'true'
        let texts = en
        if (userLanguage.startsWith('pt')) {
            texts = pt
        }

        if(params.get('lang') === 'pt'){
            texts = pt
        }else if(params.get('lang') === 'en'){
            texts = en
        }

        this.state = {
            texts: texts,
            cvMode: cvMode
        };

    }

    changeCVMode() {
        this.setState({cvMode: !this.state.cvMode})
    }

    render() {
        return (
            <div className={'App ' + (this.state.cvMode ? 'cvmode' : '')}>
                {!this.state.cvMode && <Header texts={this.state.texts}/>}
                <div className="page-content">
                    <div>
                        <Profile changeCVMode={() => {
                            this.changeCVMode()
                        }} cvMode={this.state.cvMode} texts={this.state.texts}/>
                        <About texts={this.state.texts}/>
                        <Skill cvMode={this.state.cvMode} title={"Front-End"} texts={this.state.texts}/>
                        <Skill cvMode={this.state.cvMode} title={"Back-End"} texts={this.state.texts}/>
                        <Skill cvMode={this.state.cvMode} title={"DevOps"} texts={this.state.texts}/>
                        {/*<Portfolio />*/}
                        <Experience cvMode={this.state.cvMode} texts={this.state.texts}/>
                        {!this.state.cvMode && <GitHubRepository texts={this.state.texts}/>}
                        <EducationSchools texts={this.state.texts}/>
                        <PersonalProjects texts={this.state.texts}/>
                    </div>
                    {!this.state.cvMode && <Contact texts={this.state.texts}/>}
                </div>
            </div>
        );
    }
}

export default App;
