import React, {Component} from 'react';
import Topic from "./Topic"

export default class Experience extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedTechnology: null,
            filterOn: true,
            experiencesToShow: 3, // Adicione um estado para controlar o número de experiências a serem exibidas

        };
    }

    handleTechnologyFilter = (technology) => {
        this.setState({selectedTechnology: technology});
    };

    handleLoadMore = () => {
        // Ao clicar em "Carregar Mais", incremente o número de experiências a serem exibidas
        this.setState((prevState) => ({
            experiencesToShow: prevState.experiencesToShow + 3,
        }));
    };

    render() {
        const categorizedLanguages = {
            "Front-end": ["Angular", "React", "Angular 4", "AngularJS", "HTML5"],
            "Back-end": ["C#", "SpringBoot", "NodeJs", "Swift", "PHP", "Golang", "Python", "Spring", "Java Spring Framework"],
            "DevOps": ["Jenkins", "AWS", "Docker", "Kubernetes", "Google Cloud Plataform", "Travis CI", "IBM", "WebSocket", "REST", "Vmware vsphere"]
        };

        // Filtra as experiências com base na tecnologia selecionada
        const filteredExperiences = this.state.selectedTechnology
            ? this.props.texts.experiences.filter(experience => experience.labels && experience.labels.includes(this.state.selectedTechnology))
            : this.props.texts.experiences;

        const displayedExperiences = this.props.cvMode ? this.props.texts.experiences : filteredExperiences.slice(0, this.state.experiencesToShow);

        return (
            <div className="section" id="experience">
                <div className="container cc-experience">
                    <div className="h4 text-center mb-4 title">{this.props.texts.header.experiences}</div>

                    {/* Botões de filtro por tecnologia */}
                    {!this.props.cvMode && this.state.filterOn && <div className="text-center mb-4 container">
                        <button className="btn btn-success btn-sm" onClick={() => this.handleTechnologyFilter(null)}>{this.props.texts.header.all}</button>
                        {Object.entries(categorizedLanguages).map(([title, languages]) => {
                            return <div className={"row"} key={"lang-" + title}>
                                <div className="col col-12">
                                    <h3 className={"m-0"}>{title}</h3>
                                </div>
                                <div className={"row align-items-center align-content-center content-center w-100 justify-content-center"}>
                                    {languages.map((label, index) => {
                                        return <div key={"label-" + index} className={"col col-3"}>
                                            <button className="btn btn-sm btn-block btn-info" onClick={() => this.handleTechnologyFilter(label)}>{label}</button>
                                        </div>
                                    })}
                                </div>
                            </div>
                        })}
                    </div>}

                    {/* Mapeia as experiências filtradas e cria os componentes Topic dinamicamente */}
                    {displayedExperiences.map((experience, index) => (
                        <Topic
                            key={`ex-${index}`}
                            typeDiv={experience.typeDiv}
                            year={experience.year}
                            type={experience.type}
                            title={experience.title}
                            location={experience.location}
                            description={experience.description}
                            labels={experience.labels}
                        />
                    ))}

                    {/* Botão "Carregar Mais" */}
                    {!this.props.cvMode && this.state.filterOn && this.state.experiencesToShow < filteredExperiences.length && (
                        <div className="text-center mb-4 container">
                            <button className="btn btn-primary btn-sm" onClick={this.handleLoadMore}>
                                {this.props.texts.header.loadMore}
                            </button>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}
