import React, { Component } from 'react';
import Topic from "./Topic"

export default class EducationSchools extends Component {
    render() {



        return (<div className="section">
            <div className="container cc-education">
                <div className="h4 text-center mb-4 title">{this.props.texts.header.education}</div>
                {/* Mapeia as experiências e cria os componentes Topic dinamicamente */}
                {this.props.texts.educations.map((experience, index) => (
                    <Topic
                        key={index}
                        typeDiv={experience.typeDiv}
                        year={experience.year}
                        type={experience.type}
                        title={experience.title}
                        location={experience.location}
                        description={experience.description}
                    />
                ))}
            </div>
        </div>
        )
    }
}