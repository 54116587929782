import React, {Component} from 'react';

export default class Profile extends Component {
    render() {
        return <div className="profile-page">
            <div className="wrapper">
                <div className="page-header page-header-small" filter-color="green">
                    {this.props.cvMode && <button className="btn backButton btn-primary smooth-scroll mr-2" onClick={()=>{
                        this.props.changeCVMode(true)
                    }} data-aos="zoom-in" data-aos-anchor="data-aos-anchor">{this.props.texts.profile.back}</button>}
                    <div className="page-header-image" data-parallax={this.props.cvMode.toString()}/>
                    <div className="container">
                        <div className="content-center">
                            <div className="cc-profile-image">
                                <a href="#"><img src="images/guilhermecamachopinto.jpeg" alt="Image"/></a>
                            </div>
                            <div className="h2 title">Guilherme Pinto</div>
                            <p className="category text-white">{this.props.texts.profile.job}</p>
                            {!this.props.cvMode && <a className="btn btn-primary smooth-scroll mr-2" href="#contact" data-aos="zoom-in" data-aos-anchor="data-aos-anchor">{this.props.texts.profile.contact}</a>}
                            {!this.props.cvMode && <button className="btn btn-primary smooth-scroll mr-2" onClick={()=>{
                                this.props.changeCVMode(true)
                            }} data-aos="zoom-in" data-aos-anchor="data-aos-anchor">{this.props.texts.profile.cv}</button>}
                        </div>
                    </div>
                    {!this.props.cvMode && <div className="section">
                        <div className="container">
                            <div className="button-container">
                                <a className="btn btn-default btn-round btn-lg btn-icon"
                                   href="https://twitter.com/guitersp"
                                   rel="tooltip" target="_blank" title="Me siga no Twitter">
                                    <i className="fa fa-twitter"/>
                                </a>
                                <a className="btn btn-default btn-round btn-lg btn-icon"
                                   href="https://www.instagram.com/guitersp/?hl=pt-br" rel="tooltip"
                                   title="Me siga no Instagram"
                                   target="_blank">
                                    <i className="fa fa-instagram"/>
                                </a>

                                <a className="btn btn-default btn-round btn-lg btn-icon"
                                   href="https://www.linkedin.com/in/guilhermep2023/" rel="tooltip"
                                   title="Me siga no Linkedin"
                                   target="_blank">
                                    <i className="fa fa-linkedin"/>
                                </a>
                                <a className="btn btn-default btn-round btn-lg btn-icon"
                                   href="https://github.com/VivaldiCode"
                                   rel="tooltip" title="Me siga no GitHub"
                                   target="_blank">
                                    <i className="fa fa-github-alt"/>
                                </a>
                            </div>
                        </div>
                    </div>}
                </div>
            </div>
        </div>
    }
}