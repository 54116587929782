import React, {Component} from 'react';

const currentYear = new Date().getFullYear();
const totalEx = currentYear - 2007

export default class App extends Component {
    calculateExperiencePercentage(yearStart) {
        const totalYears = currentYear - yearStart;
        const yearsOfExperience = Math.min(totalYears, totalEx);
        return (yearsOfExperience / totalEx) * 100; // Calculando a porcentagem
    }

    render() {
        const experience = {
            "Front-End":[
                {title: "HTML/HTML5", yearStart: 2007},
                {title: "CSS/CSS3", yearStart: 2007},
                {title: "JAVASCRIPT", yearStart: 2007},
                {title: "REACT / REACTNATIVE", yearStart: 2013},
                {title: "ANGULAR", yearStart: 2014},
            ],
            "Back-End":[
                {title: "PHP", yearStart: 2007},
                {title: "PYTHON", yearStart: 2007},
                {title: "C#", yearStart: 2009},
                {title: "ASSEMBLY", yearStart: 2010},
                {title: "C", yearStart: 2010},
                {title: "Node.JS", yearStart: 2010},
            ],
            "DevOps":[
                {title: "AWS", yearStart: 2015},
                {title: "GCP", yearStart: 2015},
                {title: "IBM BlueMix", yearStart: 2015},
                {title: "AZURE", yearStart: 2015},
                {title: "DOCKER", yearStart: 2015},
                {title: "KUBERNETES", yearStart: 2015},
            ]
        }

        return (
            <div className="section" id="skill">
                <div className="container">
                    <div className="h4 text-center mb-4 title">{this.props.texts.skill.knowledge} - {this.props.title}</div>

                    <div className="card" data-aos="fade-up" data-aos-anchor-placement="top-bottom">
                        <div className="card-body">
                            <div className="row">
                                {experience[this.props.title] && experience[this.props.title].map((item, index) => (
                                    <div key={'ex-' + index} className="col-md-6">
                                        <div className={`progress-container progress-primary`}>
                                            <span className="progress-badge">{item.title}</span>
                                            <div className="progress">
                                                <div
                                                    className="progress-bar progress-bar-primary"
                                                    data-aos="progress-full"
                                                    data-aos-offset="0"
                                                    data-aos-duration={this.props.cvMode ? 1 : 2000}
                                                    role="progressbar"
                                                    aria-valuenow="100"
                                                    aria-valuemin="0"
                                                    aria-valuemax="100"
                                                    style={{width: `${this.calculateExperiencePercentage(item.yearStart)}%`}}
                                                ></div>
                                                <span className="progress-value">{`${Math.min(new Date().getFullYear() - item.yearStart, totalEx)} ${this.props.texts.skill.years}`}</span>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                {/* Adicione mais colunas conforme necessário */}
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}