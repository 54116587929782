import React, {Component} from 'react';

export default class About extends Component {

    render() {
        return <div className="section" id="about">
            <div className="container">
                <div className="card" data-aos="fade-up" data-aos-offset="10">
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <div className="card-body">
                                <div className="h4 mt-0 title text-black">{this.props.texts.about.basicInfo}</div>
                                {this.props.texts.about.aboutText.map((line, index) => {
                                    return <p key={"line-" + index}>{line}</p>
                                })}
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="card-body">
                                <div className="row mt-3">
                                    <div className="col-sm-4"><strong className="text-uppercase">{this.props.texts.about.age}:</strong></div>
                                    <div className="col-sm-8">{new Date().getFullYear() - 1992}</div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-sm-4"><strong className="text-uppercase">Email:</strong></div>
                                    <div className="col-sm-8">contato@guilhermepinto.pt</div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-sm-4"><strong className="text-uppercase">{this.props.texts.about.location}:</strong>
                                    </div>
                                    <div className="col-sm-8">Portugal</div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-sm-4"><strong className="text-uppercase">{this.props.texts.about.languages}:</strong></div>
                                    <div className="col-sm-8">English, Portuguese</div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}